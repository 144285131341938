(function ($, Drupal) {
  var contentBlockOverlay = {
    setupOverlay: function (context) {
      var self = this;

      self.$overlays = $('.js-content-block-overlay--v1', context);
      self.$overlays.each(function () {
        var $dialog = $(this).find('[role="dialog"]');
        var $closeDialog = $('.js-content-block-overlay-close', $dialog);

        $closeDialog.once().on('click.close', function () {
          var closeButton = $(this).get(0);
          var topDialog = aria.getCurrentDialog();

          if (topDialog.dialogNode.contains(closeButton)) {
            topDialog.close();
          }
        });
      });
    },
    launchOverlay: function (context, indexId) {
      var self = this;
      var targetOverlay = self.$overlays[indexId];
      var $dialog = $(targetOverlay).find('[role="dialog"]');

      $(targetOverlay).insertAfter($('body'));
      new aria.Dialog($dialog.attr('id'), self);
    }
  };

  Drupal.behaviors.contentBlockOverlayV1 = {
    $overlays: $(),
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      contentBlockOverlay.setupOverlay(context);
    }
  };
  $(document).on('launch.overlay', function (e, indexId) {
    contentBlockOverlay.launchOverlay(this, indexId);
  });
})(jQuery, Drupal);
